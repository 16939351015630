import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useAuth } from './hook/AuthHook';
import React, { Suspense, lazy } from "react";
import { AuthContext } from './context/AuthContext';
import AuthLogin from './Auth';

// Lazy-loaded components
const Login = lazy(() => import("./component/login/Login"));
const Dashboard = lazy(() => import("./component/dashboard/Dashboard"));
const Role = lazy(() => import("./component/role/Role"));
const CardContainer = lazy(() => import("./component/dashboard/CardContainer"));
const Idn = lazy(() => import("./component/idn/Idn"));
const Plant = lazy(() => import("./component/plant/Plant"));
const Site = lazy(() => import("./component/site/Site"));
const MultiSite = lazy(() => import("./component/multiSite/MultiSite"));
const PlantConfig = lazy(() => import("./component/plantconfig/PlantConfig"));
const User = lazy(() => import("./component/user/User"));
const Survey = lazy(() => import("./component/survey/Survey"));
const PlantQASurvey = lazy(() => import("./component/plantQASurvey/PlantQASurvey"));
const SurveyGroup = lazy(() => import("./component/surveyGroup/SurveyGroup"));
const PlantQAConfig = lazy(() => import("./component/plantQAConfig/PlantQAConfig"));
const PlantQualityAudit = lazy(() => import("./component/plantQualityAudit/PlantQualityAudit"));
const QualityAuditDashboard = lazy(() => import("./component/qualityAuditDashboardOverview/QualityAuditDashboard"));
const QualityDashboardDetail = lazy(() => import("./component/qualityAuditDashboardOverview/QualityDashboardDetail"));
const QualityDashboardToday = lazy(() => import("./component/qualityAuditDashboardOverview/QualityDashboardToday"));
const FacilityScreen = lazy(() => import("./component/facilitySurveyDashboard/FacilityScreen"));
const ScoreCard = lazy(() => import("./component/scorecard/Scorecard"));
const Utilization = lazy(() => import("./component/utilization/Utilization"));
const DefaultPage = lazy(() => import("./component/defaultPage/DefaultPage"));
const ExternalClientSurvey = lazy(() => import("./component/externalClientSurvey/ExternalClientSurvey"));
const CustomerServicePerformance = lazy(() => import("./component/customerServicePerformance/CustomerServicePerformance"));
const ReportContainer = lazy(() => import("./component/reports/ReportContainer"));
const SurveyReport = lazy(() => import("./component/surveyReport/SurveyReport"));
const UserLog = lazy(() => import("./component/userLog/UserLog"));
const ErrorLog = lazy(() => import("./component/errorLog/ErrorLog"));
const SamSummaryOfDay = lazy(() => import("./component/samSummaryOfDay/SamSummaryOfDay"));
const AddNewFacilityRequest = lazy(() => import("./component/addNewfacilityRequest/AddNewFacilityRequest"));
const SummaryOfDayScreen = lazy(() => import("./component/summaryOfDayScreen/SummaryOfDayScreen"));
const SummaryOfDay = lazy(() => import("./component/summaryOfDay/SummaryOfDay"));
const SamSummaryScreen = lazy(() => import("./component/samSummaryScreen/SamSummaryScreen"));

function App() {
    const { token, login, logout, userId } = useAuth();

    let routes = (
        <React.Fragment>
            <Route element={<AuthLogin />}>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route
                    path="/externalSurvey/:uniqueId"
                    loader={({ params }) => {}}
                    action={({ params }) => {}}
                    element={<ExternalClientSurvey />}
                />
            </Route>
            <Route element={<Dashboard />}>
                <Route path="/dashboard" element={<CardContainer />} />
                <Route path="/role" element={<Role />} />
                <Route path="/idn" element={<Idn />} />
                <Route path="/plant" element={<Plant />} />
                <Route path="/site" element={<Site />} />
                <Route path="/multiSite" element={<MultiSite />} />
                <Route path="/plantconfig" element={<PlantConfig />} />
                <Route path="/user" element={<User />} />
                <Route path="/survey" element={<Survey />} />
                <Route path="/surveyGroup" element={<SurveyGroup />} />
                <Route path="/plantSurvey" element={<PlantQASurvey />} />
                <Route path="/plantQAConfig" element={<PlantQAConfig />} />
                <Route path="/plantQualityAudit" element={<PlantQualityAudit />} />
                <Route path="/qualityAuditDashboard" element={<QualityAuditDashboard />} />
                <Route path="/qualityDetail" element={<QualityDashboardDetail />} />
                <Route path="/qualityTodayDetail" element={<QualityDashboardToday />} />
                <Route path="/facilitySurveyDashboard" element={<FacilityScreen />} />
                <Route path="/scorecard" element={<ScoreCard />} />
                <Route path="/utilization" element={<Utilization />} />
                <Route path="/customerServicePerformance" element={<CustomerServicePerformance />} />
                <Route path="/reports" element={<ReportContainer />} />
                <Route path="/surveyReport" element={<SurveyReport />} />
                <Route path="/userLog" element={<UserLog />} />
                <Route path="/errorLog" element={<ErrorLog />} />
                <Route path="/defaultPage" element={<DefaultPage />} />
                <Route path="/summaryOfDay" element={<SummaryOfDay />} />
                <Route path="/samSummaryOfDay" element={<SamSummaryOfDay />} />
                <Route path="/summaryOfDayScreen" element={<SummaryOfDayScreen />} />
                <Route path="/samSummaryScreen" element={<SamSummaryScreen />} />
                <Route path="/newFacilityRequest" element={<AddNewFacilityRequest />} />
            </Route>
            <Route
                path="*"
                element={
                    <div style={{ padding: "1rem" }}>
                        <p>There's nothing here!</p>
                    </div>
                }
            />
        </React.Fragment>
    );

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                login: login,
                logout: logout,
            }}
        >
            <BrowserRouter>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>{routes}</Routes>
                </Suspense>
            </BrowserRouter>
        </AuthContext.Provider>
    );
}

export default App;
